const AFK = () => {
    return (
        <section id='afk'>
            <h2>
                AFK, you'll find me:
            </h2>
            <ul>
                <li>
                    Throwing pottery, riding mountain bikes, climbing rocks, hiking mountains, petting cats, traveling the world, practising yoga, repairing clothes, & playing boardgames
                </li>
            </ul>
        </section>
    );
};

export default AFK;