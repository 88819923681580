const Error404 = () => {
    return (
        <main id='error-404' className='wrapper'>
            <h2>404</h2>
            <p>
                oopsies!
            </p>
        </main>
    );
};

export default Error404;